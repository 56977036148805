import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { b2cPolicies, loginRequest, msalConfig } from './authConfig';
import { MsalAuthService } from "./authService.js";
import App from './App';
import Cookies from "js-cookie";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

let isEmbedded = false
if (window !== window.parent) {
    isEmbedded = true
}
export const CallCenterInstanceName = "cogswell-call-center";

// Look for optional "lang" query parameter and use it if provided
const langParam = new URLSearchParams(window.location.search).get("lang");
if (langParam) {
    // Store preferred language in a cookie so that it's persistent across requests to this app
    Cookies.set("lang", langParam);
}

const Language = Cookies.get("lang") || "en";
const Translations = {
    en: {
        CUSTOMER_SUPPORT_REQUESTS: "Customer Support Requests",
        CUSTOMER: "Customer",
        CUSTOMER_CONTACT: "Contact",
        ONSIGHT_USER: "Onsight User",
        REQUEST_DATETIME: "Request Date/Time",
        STATUS: "Status",

        Canceled: "Canceled",
        NotStarted: "Not Started",
        RequiresCallback: "Requires Callback",
        OnConsulting: "On Consulting",
        Done: "Done",

        CUSTOMER_COMMENT: "Customer Comment",
        TRANSCRIPT: "Transcript",
        RESPONDER: "Responder",
        INITIALIZING_CALL: "Initializing Call",
        PREPARING_TO_CALL: "Preparing to call %s...",
        SORRY_TAKING_LONGER: "Sorry, this is taking longer than expected.",
        CALL_NOW: "Call Now",
        CANCEL: "Cancel",
        CALL_INITIATED_YOU_MAY_CLOSE:
            "Call initiated. You may now close this window.",
        CALL: "Call",
        LINK: "Link",
        NO_LINK: "None",
        PENDING_LINK: "Pending",
        PLEASE_ASK_YOUR_ADMINISTRATOR:
            "Please ask your administrator for instructions.",
        ACCESS_RESTRICTED: "Access Restricted",
        MANAGEMENT_PORTAL: "Management Portal",
        NEW_REQUESTS_AVAILABLE: "New requests available. Click to reload.",
        LOADING: "Loading",
        PAGE_WILL_AUTO_CLOSE: "This page will automatically close in %d seconds"
    }
};
export const translate = (key) => {
    const table = Translations[Language];
    if (!table) {
        return key;
    }
    return table[key] || key;
};

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
export const authService = new MsalAuthService(isEmbedded, msalInstance, {
    signInAuthority: b2cPolicies.authorities.signIn.authority,
    signInScopes: loginRequest.scopes
});

let currentPage = {};
export const setCurrentPage = (page) => (currentPage = page);
export const getCurrentPage = () => currentPage;

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

document.title = translate("MANAGEMENT_PORTAL");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={isEmbedded ? InteractionType.Popup : InteractionType.Redirect} authenticationRequest={loginRequest}>
                <App authService={authService} />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </Router>
);
