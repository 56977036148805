import React, { useState, useEffect, useRef } from "react";
import { matchRoutes, Route, Routes, useSearchParams } from "react-router-dom";
import { RestrictedPage } from "./pages/RestrictedPage";
import { LoadingPage } from "./pages/LoadingPage";
import { UserWebApps } from "./onsight-plus-ext";
import { Container } from "./components/dashboard/Container";
import { Admin } from "./components/dashboard/Admin";
import { NotFound } from "./components/NotFound";
import { Requests } from "./components/Requests";
import CallLaunchPage from "./components/CallLaunchPage";
import { setCurrentPage } from "./index";

import "./styles/index.css";
import "./styles/App.css";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const App = ({ authService }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [appPages, setAppPages] = useState([]);
  const [pageId, setPageId] = useState("");
  const pageIdRef = useRef("");
  pageIdRef.current = pageId;
  const [configId, setConfigId] = useState("");
  const configIdRef = useRef("");
  configIdRef.current = configId;
  const [searchParams, _] = useSearchParams();
  const [userRoutes, setUserRoutes] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const isAdminRef = useRef(false);
  isAdminRef.current = isAdmin;

  const [isProd, setIsProd] = useState(true);
  const isProdRef = useRef(true);
  isProdRef.current = isProd;

  const onAuthorize = async (userAccount) => {
    setCurrentUser(userAccount);
    let pageIdParam = searchParams.get("pageId");
    pageIdRef.current = pageIdParam;
    setPageId(pageIdParam);

    let configIdParam = searchParams.get("configId");
    configIdRef.current = configIdParam;
    setConfigId(configIdParam);

    try {
      userAccount.roles.forEach((r) => {
        if (r.includes("Administrator")) {
          isAdminRef.current = true;
          setIsAdmin(true);
        }
      });

      // determine if this is being launch in non-dev environment
      if (isAdminRef.current) {
        const url = window.location;
        if (url.hostname === "localhost") {
          isProdRef.current = false;
          setIsProd(false);
        }
      }

      const userPages = await UserWebApps.getUserPages();
      setAppPages(userPages);

      const userRoutes = userPages.map((page) => {
          return (
              <Route {...page}
                  key={page.id}
          element={<Requests />} />
      )});

      // Add some standard routes in addition to user-specific ones
      userRoutes.push(<Route key="call" path="/call" element={<CallLaunchPage />} />);
      userRoutes.push(<Route key="_404" path="*" element={<NotFound status={404} />} />);

      if (isAdminRef.current) {
        userRoutes.push(
          <Route
            key="_admin"
            path="/admin"
            element={<Admin pageId={pageIdRef.current} configId={configIdRef.current} userAccount={currentUser} isAdmin={true} isProd={isProdRef.current} />}
          />
        );
      }

      setUserRoutes(userRoutes);
      setIsAuthorized(true);
    } catch (error) {
      setIsAuthorized(false);
      setNotAuthorized(true);
    }
  };

  useEffect(() => {
    authService.addAuthorizationHandler(onAuthorize);
  }, []);

  useEffect(() => {
    if (appPages !== null && appPages !== undefined) {
      const matches = matchRoutes(appPages, location);
      if (matches?.length > 0) {
          setCurrentPage(matches[0].route);
      }
    }
  }, [appPages]);

  return (
    <>
      {isAuthorized && (
        <>
          <div style={{margin: "20px 20px 10px 0px", textAlign: "right"}}>
            <span style={{fontSize: "x-large", fontWeight: "bold", marginRight: "30px"}}>Customer Support</span>
            <img src="/images/cogswell-logo-sm.png" title="Logo" style={{height: "60px"}}></img>
          </div>

          <Routes>
            {userRoutes}
          </Routes>
        </>
      )}
      {notAuthorized && <RestrictedPage />}
      {!isAuthorized && !notAuthorized && <LoadingPage />}
    </>
  );
};
export default App;
