import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { CallCenter } from "../onsight-plus-ext";
import { CallCenterInstanceName, translate } from "../index";
import { sprintf } from "sprintf-js";

const CallLaunchPage = () => {
    const AutoCloseTimeout = 20000;
    const [isInitializing, setIsInitializing] = useState(true);
    const isInitializingRef = useRef(isInitializing);
    isInitializingRef.current = isInitializingRef;
    const [showTakingTooLong, setShowTakingTooLong] = useState(false);
    const [currentOCUrl, setCurrentOCUrl] = useState("");
    const [searchParams, _] = useSearchParams();
    const customerName = searchParams.get("customerName");
    const requestId = searchParams.get("reqId");
    const directCall = searchParams.get("direct");
    const [autoClose, setAutoClose] = useState(false);
    const [autoCloseCountdown, setAutoCloseCountdown] = useState(AutoCloseTimeout / 1000);
    const autoCloseCountdownRef = useRef(AutoCloseTimeout / 1000);

    const redirectToOC = (ocUrl) => {
        const url = ocUrl || currentOCUrl;
        if (url) {
            window.location.href = url;
            setIsInitializing(false);
            if (!url.startsWith("http")) {
                setAutoClose(true);
            }
        }
    };

    const handleCancelCallClick = () => {
        setIsInitializing(false);
        setCurrentOCUrl("");
        setShowTakingTooLong(false);
        window.close();
    };

    const handleForceStartOCClick = () => {
        redirectToOC();
        setShowTakingTooLong(false);
    };

    const initiateGuestCall = async (connectionId) => {
        const response = await CallCenter.initiateCall(CallCenterInstanceName, requestId, connectionId);
        setCurrentOCUrl(response.data);

        setTimeout(() => {
            if (isInitializingRef.current) {
                setShowTakingTooLong(true);
            }
        }, 10000);
    };

    const initiateDirectCall = async () => {
        try {
            const response = await CallCenter.initiateCall(CallCenterInstanceName, requestId);
            redirectToOC(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (directCall) {
            initiateDirectCall();
            return;
        }

        const websocketUrl = `${CallCenter.callLaunchHubUrl}?extName=${CallCenterInstanceName}`;
        const connection = new HubConnectionBuilder()
            .withUrl(websocketUrl)
            .withAutomaticReconnect()
            .build();

        connection.start()
            .then(result => {
                connection.on('CallRequester', ocUrl => {
                    console.log(`Got CallCenterResponderCall notification for URL ${ocUrl}`);
                    redirectToOC(ocUrl);
                });
                initiateGuestCall(connection.connectionId);
            })
            .catch(e => console.log('Connection failed: ', e));
    }, []);

    useEffect(() => {
        if (autoClose) {
            setInterval(() => setAutoCloseCountdown(autoCloseCountdownRef.current - 1), 1000);
            setTimeout(() => window.close(), AutoCloseTimeout);
        }
    }, [autoClose]);

    useEffect(() => {
        autoCloseCountdownRef.current = autoCloseCountdown;
    }, [autoCloseCountdown]);

    return (
        <>
            <h2>{translate("INITIALIZING_CALL")}</h2>
            {isInitializing && <>
            {sprintf(translate("PREPARING_TO_CALL"), customerName)}            
                {showTakingTooLong && <><br /><span>{translate("SORRY_TAKING_LONGER")}</span></>}
            <br />
            <div style={{ display: "flex", justifyContent: "center", padding: "40px 0px" }}>
                <Spinner animation="border" size="xxl" />
            </div>
            {showTakingTooLong && <Button className="btn btn-primary" onClick={handleForceStartOCClick}>{translate("CALL_NOW")}</Button>}
            {showTakingTooLong && <Button className="btn btn-secondary" onClick={handleCancelCallClick}>{translate("CANCEL")}</Button>}
            </>}
            {!isInitializing && <h4>{translate("CALL_INITIATED_YOU_MAY_CLOSE")}</h4>}
            {autoClose && <h3>{sprintf(translate("PAGE_WILL_AUTO_CLOSE"), autoCloseCountdown)}</h3>}
        </>
    );
};

export default CallLaunchPage;