import { Body } from "./Body";

export const Main = (props) => {
  return (
    <div className="App">
      {
        <Body
          isAdmin={props.isAdmin}
          onLayoutChange={props.onLayoutChange}
          layouts={props.layouts}
          handleClick={props.handleClick}
          value={props.vin}
          content={props.content}
          blockConfig={props.blockConfig}
          setLayouts={props.setLayouts}
        />
      }
    </div>
  );
};
