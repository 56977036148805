import { SizeAwareDraggableParent } from "./DraggableParent";

export const Body = (props) => {
  return (
    <div id="navbar-container-main" className="container-md">
      {
        <SizeAwareDraggableParent
          isAdmin={props.isAdmin}
          onLayoutChange={props.onLayoutChange}
          content={props.content}
          layouts={props.layouts}
          contentArr={props.blockConfig}
          setLayouts={props.setLayouts}
        />
      }
    </div>
  );
};
